import types from "./types";
import config from "../config/app.conf.json";
import { CALL_API } from "@cdkglobal/react-core-reduxapi";
import { sendEmailCSMG } from "./constants";
import axios from 'axios'

export const actions = {
  getAppsByName: ({ accessToken, appName }) => {
    return {
      [CALL_API]: {
        url: `${config.api.solutions_url_v2}?q=${appName}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json"
        },
        types: [
          types.GET_APPS_REQUEST,
          types.GET_APPS_RECEIVE,
          types.GET_APPS_ERROR
        ]
      }
    };
  },
  getAppById: ({ accessToken, appId }) => {
    return {
      [CALL_API]: {
        url: `${config.api.solutions_url}/${appId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json"
        },
        types: [
          types.GET_APP_BY_ID_REQUEST,
          types.GET_APP_BY_ID_RECEIVE,
          types.GET_APP_BY_ID_ERROR
        ]
      }
    };
  },
  getAsyncApiIntegrations: ({ accessToken, appId }) => {
    return {
      [CALL_API]: {
        url: `${config.api.async_apis_control_url}/v1/admin/async-api-integrations?appId=${appId}&returnRetiredAsyncIntegrations=true`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json"
        },
        types: [
          types.GET_ASYNC_API_INTEGRATIONS_REQUEST,
          types.GET_ASYNC_API_INTEGRATIONS_RECEIVE,
          types.GET_ASYNC_API_INTEGRATIONS_ERROR
        ]
      }
    };
  },
  getRestApiIntegrations: ({ accessToken, appId }) => {
    return {
      [CALL_API]: {
        url: `${config.api.api_gateway_url}/v1/admin/api-pricing?appId=${appId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json"
        },
        types: [
          types.GET_REST_API_INTEGRATIONS_REQUEST,
          types.GET_REST_API_INTEGRATIONS_RECEIVE,
          types.GET_REST_API_INTEGRATIONS_ERROR
        ]
      }
    };
  },
  getAppISVDetails: ({ accessToken, uid }) => {
    return {
      [CALL_API]: {
        url: `${config.api.accounts_base_url}/api/v1/users/profile/${uid}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json"
        },
        types: [
          types.GET_APP_ISV_DETAILS_REQUEST,
          types.GET_APP_ISV_DETAILS_RECEIVE,
          types.GET_APP_ISV_DETAILS_ERROR
        ]
      }
    };
  },

  freeTrialOverrideEmailNotification: ({ appName, apiDetails, accessToken,emailRecieptents }) => {
    const template = {
      emailType: 'freeTrialOverride',
      appDetails: {
        appName: appName
      },
      apiDetails: apiDetails
    };
    return dispatch => {
      dispatch({
        type: types.SEND_EMAIL_REQUEST
      });
      axios
        .post(
          `${config.api.marketplace_service_url}/graphql`,
          {
            query: sendEmailCSMG,
            variables: {
              to: emailRecieptents,
              bcc: [{ email: config.adminEmail }],
              subject: 'API Free Trial End Date Modified',
              withTemplate: template
            }
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        .then(res => {
          dispatch(
            getResult(types.SEND_EMAIL_RECEIVE, { ...res.data })
          );
        })
        .catch(error => {
          if (error.response) {
            dispatch(
              getResult(types.SEND_EMAIL_ERROR, error.response)
            );
          } else {
            dispatch(getResult(types.SEND_EMAIL_ERROR, {}));
          }
        });
    };
  },

  
  
  resetData: () => ({ type: types.RESET_ALL }),
  updateRestIntegrations: ({ accessToken, data }) => {
    return {
      [CALL_API]: {
        url: `${config.api.api_gateway_url}/v1/admin/api-integrations`,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json"
        },
        data: data,
        types: [
          types.UPDATE_REST_API_INTEGRATIONS_REQUEST,
          types.UPDATE_REST_API_INTEGRATIONS_RECEIVE,
          types.UPDATE_REST_API_INTEGRATIONS_ERROR
        ]
      }
    };
  },
  updateAsyncIntegrations: ({ accessToken, data }) => {
    return {
      [CALL_API]: {
        url: `${config.api.async_apis_control_url}/v1/admin/async-api-integrations`,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json"
        },
        data: data,
        types: [
          types.UPDATE_ASYNC_API_INTEGRATIONS_REQUEST,
          types.UPDATE_ASYNC_API_INTEGRATIONS_RECEIVE,
          types.UPDATE_ASYNC_API_INTEGRATIONS_ERROR
        ]
      }
    };
  },
  resetPatchApisData: () => ({ type: types.RESET_PATCH_API_DATA }),
  resetSearchData: () => ({ type: types.RESET_SEARCH_DATA })
};
const getResult = (actionType, res) => {
  return {
    type: actionType,
    response: res
  };
};
