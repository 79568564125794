const  SOLUTION_STATUS ={
CANCELLED:"Cancelled"
}
const APP_CANCELLED_TEXT = "This app is cancelled!";
const APP_CERTIFICATION_TEXT = "This app is not yet certified!";
const LOADING_TEXT = "Fetching solution details...";
const BULK_SUBSCRIPTION_APP_CANCELLED_TEXT = "You cannot request bulk subscription for a cancelled app."

const getTypeOfApis = (apiType) => {
  if (apiType === "api") return "Rest";
  if (apiType === "async-api") return "Async";
  return apiType;
};
const capitalizeVisibility = (str) => {
  return str ? str[0].toUpperCase() + str.slice(1) : undefined;
};

const API_INTEGRATION_STATUS = {
  ACTIVE: 'active',
  PENDING: 'pending'
}

const API_TYPES = {
  REST: 'api',
  ASYNC: 'async-api'
}
export {
    SOLUTION_STATUS,
    APP_CANCELLED_TEXT,
    APP_CERTIFICATION_TEXT,
    LOADING_TEXT,
    BULK_SUBSCRIPTION_APP_CANCELLED_TEXT,
    API_INTEGRATION_STATUS,
    API_TYPES,
    getTypeOfApis,
    capitalizeVisibility
}