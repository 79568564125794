export const FORTELLIS_DEFINED_ORG_TYPES = [
  "Dealer",
  "ISV",
  "OEM",
  "Group",
  "Internal",
  "Test"
];

export const FORTELLIS_DEFINED_ORG_TYPE_SELECTIONS = FORTELLIS_DEFINED_ORG_TYPES.map(t => ({
  label: t,
  value: t
}));
