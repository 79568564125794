import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import axios from "axios";
import config from "../config/app.conf.json";
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
    CardWrapper,
    CardBody,
    Loader,
    Column, Grid, Row,
    PrimaryButton,
    SecondaryButton,
    Dialog,
    BUTTON_VARIANTS,
    fortellisTheme,
    Toast,
    TOAST_POSITIONS,
    TOAST_VARIANTS,
    TextArea,
    DatePicker,
    Button,
    IconInfo,
    INPUT_SIZES,
    withTooltip,
  TOOLTIP_HORIZONTAL_ALIGNMENTS,
  TOOLTIP_VERTICAL_ALIGNMENTS,
  THEMES
} from "cdk-radial";
import { ThemeProvider } from "styled-components";
import "./style.css";
import { APIDetailsTable } from "./APIDetailsTable";
import { StatusIndicator } from "./StatusIndicator";
import { sendEmailCSMG } from "../ApiIntegrationManagement/constants";

import { CERTIFICATION_STATUS, SOLUTION_STATUS, DIALOG_CONST, TOAST_MSG, ACTION_BUTTONS, ERROR_MSG, INPUT_LABEL, CERTIFICATION_MANAGEMENT_CDK_ORG_APP_TEXT } from "./certificationConstants";
import { API_INTEGRATION_STATUS } from "../Utils/constants";

const SAVE_DIALOG_TITLE = "Save Certification Fee Due Date";
const SAVE_DIALOG_MSG =
  "Are you sure you want to save Certification Fee Due Date changes?";

const AppDetails = (props) => {
    const {
        auth: {
            accessToken,
            userData
        }
    } = props;
    const [loading, setLoading] = useState(false);
    const [orgDetailsLoading, setOrgDetailsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("")
    const [solutionData, setSolutionData] = useState('');
    const [orgName, setOrgName] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [orgId, setOrgId] = useState('');
    const [solutionId, setSolutionId] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState("");
    const [toastVariant, setToastVariant] = useState(TOAST_VARIANTS.POSITIVE);
    const history = useHistory()
    const location = useLocation();
    const params = useParams();
    const [date, setDate] = useState();
    const [certificationDateChanged,setCertificationDateChanged]= useState(false);
    const [hasInputError, setHasInputError] = useState(false);
    const [isCdkOrg, setIsCdkOrg] =useState(false);
    const [restIntegrations,setRestIntegrations] = useState([]);
    const [asyncIntegrations,setAsyncIntegrations] = useState([]);
    const [loadApiDetails,setLoadApiDetails] =useState(false)
    const [errorMessage,setErrorMessage] = useState('');
    const blockedDateRanges = [{
        startDate: moment(Date.now()),
        endDate: moment(Date.now())
    }];
    const [refresh, setRefresh] =useState(false);
    const handleError = errors => {
        if(errors && errors.includes('datePicker.invalidDateInPast')){
            setHasInputError(true);
            setErrorMessage('Date cannot be in the past. Enter a valid date.')
        }
        else if(errors && errors.includes('dateInput.invalidDate')){
            setHasInputError(true);
            setErrorMessage('Enter a valid date.')
        }
        else{
            setHasInputError(errors.length>0);
            setErrorMessage('Date can not be blank.');
        }
    };
    const handleDateChange = momentDateObject => {
        setDate(momentDateObject);
        setCertificationDateChanged(true);
    };

    const IconInfoWithTooltip = withTooltip(IconInfo, {
        id: `info_icon-tooltip`,
        text:"The due date can't change during recertification.",
        theme: THEMES.LIGHT
      });

    const handleDateClear = () => {
        setDate(null);
    };

    const ButtonWithToolTip = () => {
        const tooltipProps = {
          id: "tooltip-id-2",
          text: CERTIFICATION_MANAGEMENT_CDK_ORG_APP_TEXT,
          theme: THEMES.LIGHT,
        };
        /* eslint-disable react/prop-types */
        const ComponentToRender = ({ setTooltipText, showTooltip, ...other }) => {
          if (isCdkOrg) {
            showTooltip(true);
          } else {
            showTooltip(false);
          }
          const handleChange = () => {
            showTooltip(true);
          };
          return (
            <div>
                 <Button
                dataTestId="btn-save-id"
                onChange={handleChange}
                isDisabled={
                  !date || !certificationDateChanged || isCdkOrg || hasInputError ? true : false
                }
                style={{
                  height: "2.5rem",
                  fontSize: "16px",
                  padding: "1rem",
                  width: "180px",
                  marginRight: "16px"
                }}
                text="Save"
                onClick={() => setIsOpen(true)}
                {...other}
              />
            </div>
          );
        };
        const ComponentWithTooltip = withTooltip(ComponentToRender, tooltipProps);
        return (
          <div className="save-button-tooltip">
            <ComponentWithTooltip
              horizontalAlignment={TOOLTIP_HORIZONTAL_ALIGNMENTS.LEFT}
              marginAroundElement={8}
              verticalAlignment={TOOLTIP_VERTICAL_ALIGNMENTS.TOP}
            />
          </div>
        );
      };

    const getRestIntegrtions = async(id)=>{
        setLoadApiDetails(true);
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${config.api.api_gateway_url}/v1/admin/api-pricing?appId=${id}`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer ".concat(accessToken)
                }
            });
            setRestIntegrations(data.items);
            setLoadApiDetails(false);
        } catch (error) {
            setLoadApiDetails(false)
            console.error(ERROR_MSG.REST_INTEGRATIONS_FETCH_FAILED, error)
        }
    }
    const updateFreeTrialEndDate = async(restApiData,asyncApiData,updatedFreeTrial)=>{
        const apiPromises = [];
        const restIntegrationPayload = restApiData.filter((item)=>{
            if((item.status ==='active' && item.apiPricingPlan?.type.toLowerCase()==='advanced') || (item.status === 'active' && item.skipPricing)){
                return item;
            }}).map((i)=>{
            return {
                apiIntegrationId: i.id,
                freeTrialEndDate: updatedFreeTrial,
                apiName: i.apiName
            }
        });
        const asyncApiIntegrationsPayload = asyncApiData.map((i)=>{
            if((i.asyncApi?.status ==='active' && i.asyncApi?.apiPricingPlan?.type.toLowerCase()==='advanced') || (i.asyncApi?.status === 'active' && i.skipPricing)){
                return i;
            }}).map((item)=>{
                if(item)
                return {
                    apiIntegrationId: item?.id,
                    freeTrialEndDate: updatedFreeTrial,
                    apiName: item?.asyncApi.name
                }
            }).filter((item)=>{
                if(item){
                return item
                }
            });

        if(restIntegrationPayload && restIntegrationPayload.length>0){
            const restApiUpdatePromise = axios({
                method: 'PATCH',
                url: `${config.api.api_gateway_url}/v1/admin/api-integrations`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer ".concat(accessToken)
                },
                data: restIntegrationPayload
            });
            apiPromises.push(restApiUpdatePromise);
        }
        if(asyncApiIntegrationsPayload && asyncApiIntegrationsPayload.length>0){
            const asyncApiUpdatePromise = axios({
                method: 'PATCH',
                url: `${config.api.async_api_service_url}/v1/admin/async-api-integrations`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer ".concat(accessToken)
                },
                data: asyncApiIntegrationsPayload
            });
            apiPromises.push(asyncApiUpdatePromise);  
        }
        return await Promise.allSettled(apiPromises);
    }
    const freeTrialOverrideEmailNotification = async ({
        appName,
        apiDetails,
        accessToken,
        emailRecieptents,
      }) => {
        const template = {
          emailType: "freeTrialOverride",
          appDetails: {
            appName: appName,
          },
          apiDetails: apiDetails,
        };
        try {
          await axios.post(
            `${config.api.marketplace_service_url}/graphql`,
            {
              query: sendEmailCSMG,
              variables: {
                to: emailRecieptents,
                bcc: [{ email: config.adminEmail }],
                subject: "API Free Trial End Date Modified",
                withTemplate: template,
              },
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
        } catch {
          (error) => {
            console.error("ERROR", error);
          };
        }
      };

    const handleSaveDate = async() =>{
            setIsOpen(false);
            setCertificationDateChanged(false);
            setLoading(true);
            try {
                const res = await axios
                    .patch(
                        `${config.api.payment_service_url}/customer/paymentTrigger`,
                        {
                            appId: solutionId,
                            chargeDate: moment(date).utc(),
                                                    },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer ".concat(accessToken)
                            }
                        },
                    );
                if(res && res.data && Array.isArray(res.data) && res.data.length>0 && res.data[0].payment_trigger_date){
                    const resApiData = [...restIntegrations];
                    const asyncApiData = solutionData.asyncApiIntegrations;

                    let convertedUtcDate = moment.utc(res.data[0].payment_trigger_date).add(30,'d').toDate();
                    let updatedFreeTrial = moment(convertedUtcDate);
                    let emailFormatUpdatedFreeTrial = moment(convertedUtcDate).local().format('MM/DD/YYYY');

                    await updateFreeTrialEndDate(resApiData,asyncApiData,updatedFreeTrial);
                    const filteredRestAPIWithAdvancePlan = restIntegrations.filter((item)=>{
                        if(item.apiPricingPlan?.type?.toLowerCase() === "advanced" || item.skipPricing){
                            return item;
                        }
                    })
                    const filteredAsyncAPIWithAdvancePlan = solutionData.asyncApiIntegrations.filter((item)=>{
                        if(item.asyncApi?.apiPricingPlan?.type?.toLowerCase() === "advanced" || item.skipPricing){
                            return item;
                        }
                    });
                    const apiDetails = [
                        ...filteredRestAPIWithAdvancePlan,
                        ...filteredAsyncAPIWithAdvancePlan
                    ];
                    setRefresh(true)
                    const apiIntegrations = apiDetails.map((m) => {
                      const apiName = m.apiType === "api" ? m.apiName : m.asyncApi.name;
                      return {
                        apiName: apiName,
                        freeTrialEndDate: emailFormatUpdatedFreeTrial,
                        apiIntegrationId: m.id,
                      };
                    });
                    let emailRecieptents = [];
                    if (
                      solutionData.developerEmail &&
                      solutionData.developerEmail !== "n/a"
                    ) {
                      emailRecieptents.push({ email: solutionData.developerEmail });
                    } else {
                      const res = await axios.get(
                        `${config.api.accounts_base_url}/api/v1/users/profile/${solutionData.createdBy}`,
                        {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                          },
                        }
                      );
                      const isvDetails = res.data;
                      emailRecieptents.push({ email: isvDetails.profile.email });
                    }
                    apiIntegrations && apiIntegrations.length>0 && freeTrialOverrideEmailNotification({
                      accessToken,
                      appName: solutionData.displayName,
                      apiDetails: apiIntegrations,
                      emailRecieptents: emailRecieptents,
                    });
                    setError(false);
                    setShowToast(true);
                    setToastContent(TOAST_MSG.CERTIFICATION_TRIGGER_DATE_UPDATE);
                    setLoading(false);
                }
                else if(res && res.data && res.data==='No rows to update'){
                    setError(false);
                    setShowToast(true);
                    setToastContent(res.data);
                    setToastVariant(TOAST_VARIANTS.WARNING)
                    setLoading(false);
                }
            } catch (error) {
                setShowToast(true)
                setToastContent(ERROR_MSG.CERTIFICATION_TRIGGER_DATE_UPDATE)
                setToastVariant(TOAST_VARIANTS.NEGATIVE)
                setError(true);
                console.error(error, 'ERROR');
            }
    }
   const cdkOrg = (orgDetails) => {
        return (
          orgDetails?.name?.toLowerCase().startsWith('cdk') &&
          (orgDetails.createdBy?.endsWith('@cdk.com') ||
            orgDetails.createdBy?.endsWith('@fortellis.io'))
        );
      }
    const getEntityData = async (orgId) => {
        setOrgDetailsLoading(true);
        try {
            const res = await axios
                .get(
                    `${config.api.organizations_url}/organizations/${orgId}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer ".concat(accessToken)
                        }
                    }
                );
            const orgName = res && res.data && res.data.name;
            let createdBy = JSON.parse(res?.data?.createdBy).email
            let orgDetails = {
                name: orgName,
                createdBy
            };
            setIsCdkOrg(cdkOrg(orgDetails))
            setOrgName(orgName);
            setOrgDetailsLoading(false);
        } catch (error) {
            setOrgName('Error in getting org name.');
            setOrgDetailsLoading(false);
            console.error('ERROR', error);
        }
    }

    useEffect(() => {
        if (!!location?.state) {
            setSolutionId(location?.state?.id);
            setOrgId(location?.state?.orgId);
        } else if(params?.id){
            setSolutionId(params.id)
        }
    }, [location?.state]);

    useEffect(()=>{
        if (!location?.state && !!accessToken && !!orgId) {
            getEntityData(orgId)
        }
    },[orgId])

    useEffect(() => {
        setLoading(true);
        const getSolutionData = async (solutionId) => {
            try {
                const res = await axios
                    .get(
                        `${config.api.solutions_url}/${solutionId}?expand=true&returnRetiredAsyncIntegrations=true`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer ".concat(accessToken)
                            }
                        }
                    );
                    const appDetails = res && res.data || {};
                    if(appDetails.status === SOLUTION_STATUS.CANCELLED){
                        history.push({
                            pathname: `/certification-management`,
                            state: {
                                goTo: CERTIFICATION_STATUS.READY_TO_CERTIFY
                            }
                        });
                    }
                    setError(false)
                    const activeAsyncIntegration =
                      appDetails?.asyncApiIntegrations?.filter(
                        (integration) => {
                          if (
                            integration.asyncApi &&
                            integration.asyncApi.status &&
                            integration.asyncApi.status === API_INTEGRATION_STATUS.ACTIVE
                          ) {
                            return true;
                          }
                        }
                      );
                    setAsyncIntegrations(activeAsyncIntegration);
                    setSolutionData(appDetails);
                    setOrgId(appDetails.orgId)
                    setLoading(false);
            } catch (error) {
                setErrorMsg(ERROR_MSG.GET_APP)
                setError(true);
                setLoading(false);
                console.error(ERROR_MSG.GET_APP, error);
            }
        }

        const getCertificationTriggerDate = async (solutionId) => {            
            try {
                const res = await axios
                    .get(
                        `${config.api.payment_service_url}/customer/paymentTrigger/?solnId=${solutionId}`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer ".concat(accessToken)
                            }
                        }
                    );
                const current_certification_date  = res && res.data && res.data[0]?.payment_trigger_date;
                if(current_certification_date)
                    setDate(moment(current_certification_date));
                else
                    setDate(null);
    
               setError(false)
            } catch (error) {
                setError(true);
                setErrorMsg('Error in getting certification fee due date.')
                console.error(error, 'ERROR');
            }
        }
        if (!!accessToken && !!solutionId) {
            getSolutionData(solutionId);
            getCertificationTriggerDate(solutionId);
            getRestIntegrtions(solutionId);
        }
        if (!!accessToken && !!orgId) {
            getEntityData(orgId)
        }
    }, [accessToken, solutionId,refresh])

    const updateSolution = async ({ certificationStatus, certificationDetails, certificationNotes }) => {
        const updatedData = {
            certificationStatus,
            certificationDetails,
            ...(certificationStatus === CERTIFICATION_STATUS.READY_TO_CERTIFY ? {certificationNotes}:[])
        }
        try {
            const { data } = await axios({
                method: 'PATCH',
                url: `${config.api.solutions_url}/${solutionId}`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer ".concat(accessToken)
                },
                data: updatedData
            });
            return data;
        } catch (error) {
            setShowToast(true)
            setToastContent(ERROR_MSG.UPDATE_APP)
            setToastVariant(TOAST_VARIANTS.NEGATIVE)
            setLoading(false)
            console.error(ERROR_MSG.UPDATE_APP, error)
        }
    }

    const onDialogCancel = () => setIsOpen(false)
    const handleSubmit = async ({notes}) => {
        setIsOpen(false)
        setLoading(true)
        try {
            let certificationDetails = {};
            let certificationStatus;
            let certificationNotes = [];
            let resp;

            if (solutionData.certificationStatus === CERTIFICATION_STATUS.IN_CERTIFICATION) {
                certificationStatus = CERTIFICATION_STATUS.CERTIFIED;
                certificationDetails = solutionData.certificationDetails.sort((a, b) => b.version - a.version);
                certificationDetails[0].certifiedDate = moment(new Date()).utc().toISOString();
                certificationDetails[0].certifiedBy = userData?.email;
                resp = await updateSolution({ certificationStatus, certificationDetails })
            } else if (solutionData.certificationStatus === CERTIFICATION_STATUS.CERTIFIED) {
                certificationStatus = CERTIFICATION_STATUS.READY_TO_CERTIFY;
                certificationDetails = solutionData.certificationDetails.sort((a, b) => b.version - a.version);
                certificationNotes = solutionData.certificationNotes || [];
                const note = {
                    modifiedBy: userData?.email,
                    lastModified: moment(new Date()).utc().toISOString(),
                    content: notes
                }
                certificationNotes = [...certificationNotes, note]
                const version = certificationDetails[0].version + 1;
                certificationDetails = [...certificationDetails, { version }]
                resp = await updateSolution({ certificationStatus, certificationDetails, certificationNotes });
            }
            if (resp.certificationStatus === certificationStatus) {
                setError(false);
                setShowToast(true);
                setToastContent(TOAST_MSG[certificationStatus]);
                setTimeout(() => {
                    history.push({
                        pathname: `/certification-management`,
                        state: {
                            goTo: certificationStatus
                        }
                    });
                }, 3400);
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            {
                <ThemeProvider theme={fortellisTheme}>
                    <Link
                        to={{
                            pathname: `/certification-management`
                        }}
                    >
                        <SecondaryButton
                            dataTestId="secondary-button"
                            onClick={() => { }}
                            size="large"
                            text="Back"
                            style={{
                                height: '2.5rem',
                                fontSize: '16px',
                                padding: '1rem',
                                width: '180px'
                            }}
                        />
                    </Link>
                    <CardWrapper style={{ margin: '32px 0', minHeight: "700px" }} className={
                        "s-content-route--subscription-management c-layout-section__dash-grid"
                    }>
                        <CardBody >
                            {
                                error ? <div className="body-loader">{errorMsg}</div> :
                                loading || !solutionData ? <div className="body-loader"><Loader label={<span style={{ fontSize: '18px', fontWeight: '600' }}>Loading...</span>} /></div> :
                                        solutionData && (
                                            <>
                                                <section className="page-header">
                                                    <div className="app-heading-container">
                                                        <div>
                                                            <div className="heading">{solutionData.displayName}</div>
                                                            <div className="app-detail"><span className="key">Last Updated:</span><span className="value">{solutionData.lastUpdated && moment(solutionData.lastUpdated).format('LL') || 'N/A'}</span></div>
                                                            {solutionData.mpAppName && <div className="app-detail"><span className="key">On Marketplace:</span><span className="value">{solutionData.mpAppName}</span></div>}
                                                        </div>
                                                        <div>
                                                            <StatusIndicator status={solutionData.certificationStatus} />
                                                        </div>
                                                    </div>
                                                </section>
                                                <section className="sub-section">
                                                    <div className="heading">App Details</div>
                                                    <Grid>
                                                        <Row className="info-row">
                                                            <Column l={2} className="key">App Developer</Column>
                                                            <Column className="value">{solutionData.developerEmail || 'N/A'}</Column>
                                                        </Row>
                                                        <Row className="info-row">
                                                            <Column l={2} className="key">Description</Column>
                                                            <Column l={8} className="value">{solutionData.description || 'N/A'}</Column>
                                                        </Row>
                                                        <Row className="info-row">
                                                            <Column l={2} className="key">Organization Name</Column>
                                                            <Column className="value">{orgDetailsLoading ? <Loader /> : orgName && orgName}</Column>
                                                        </Row>
                                                        <Row className="info-row">
                                                            <Column l={2} className="key">Certification Fee Due Date</Column>
                                                            <div className="cal-trigger-date">                                                        
                                                                <DatePicker 
                                                                date={date} 
                                                                dateInputProps={{
                                                                    errorMessage: errorMessage,
                                                                    isRequired: true,
                                                                    hasClearButton: (solutionData.certificationStatus === CERTIFICATION_STATUS.READY_TO_CERTIFY) && (solutionData.certificationDetails.length===1) ? true : false,                                                              
                                                                    onInputClear: handleDateClear,
                                                                    hasError: hasInputError,
                                                                    onError: handleError,
                                                                    allowDaysInPast: false,
                                                                    size: INPUT_SIZES.SMALL,
                                                                    enableCustomValidation: true,
                                                                    isDisabled : isCdkOrg || (solutionData.certificationStatus !== CERTIFICATION_STATUS.READY_TO_CERTIFY) || (solutionData.certificationDetails.length>1)
                                                                }}
                                                                numberOfMonths={2}
                                                                blockedDateRanges={blockedDateRanges}
                                                                id="certification-trigger-date-picker-id"
                                                                label="Date" name="certification-trigger-date-picker" onDateChange={handleDateChange} />
                                                            </div>
                                                            {solutionData.certificationDetails.length>1 ?
                                                                <div style={{margin: '5px'}}>                                                            
                                                                    <IconInfoWithTooltip
                                                                        horizontalAlignment={TOOLTIP_HORIZONTAL_ALIGNMENTS.LEFT}
                                                                        verticalAlignment={TOOLTIP_VERTICAL_ALIGNMENTS.TOP}/>
                                                                </div> 
                                                            : <></>
                                                            }
                                                        </Row>
                                                    </Grid>
                                                </section>
                                                <section className="sub-section">
                                                    <div className="heading">API Details</div>
                                                    <div style={{ margin: '16px 0px' }}>
                                                        <APIDetailsTable apiDetails={solutionData.integrations} activeAsyncApiIntegrations={asyncIntegrations}/>
                                                    </div>
                                                </section>
                                                <section>
                                                {
                                                    (solutionData.certificationStatus === CERTIFICATION_STATUS.READY_TO_CERTIFY) &&
                                                    <>
                                                    <ButtonWithToolTip />
    
                                                    <ConfirmationDialog
                                                        isOpen={isOpen}
                                                        onCancel={onDialogCancel}
                                                        onAccept={handleSaveDate}
                                                        title={SAVE_DIALOG_TITLE}
                                                        message={SAVE_DIALOG_MSG}
                                                    />
                                                    </>
                                                }                                                   
                                                   
                                                </section>
                                                <section className="action-button-container">

                                                    {
                                                        (solutionData.certificationStatus === CERTIFICATION_STATUS.CERTIFIED ||
                                                            solutionData.certificationStatus === CERTIFICATION_STATUS.IN_CERTIFICATION) && (
                                                            <PrimaryButton
                                                                dataTestId={`${ACTION_BUTTONS[solutionData.certificationStatus]}-action-btn`}
                                                                onClick={() => setIsOpen(true)}
                                                                size="large"
                                                                text={ACTION_BUTTONS[solutionData.certificationStatus]}
                                                                style={{
                                                                    height: '2.5rem',
                                                                    fontSize: '16px',
                                                                    padding: '1rem',
                                                                    width: solutionData.certificationStatus === CERTIFICATION_STATUS.CERTIFIED ? '220px' : '180px'
                                                                }}
                                                            />
                                                        )
                                                    }
                                                </section>
                                            </>
                                        )
                            }

                        </CardBody>
                    </CardWrapper>
                </ThemeProvider>
            }

            {(solutionData.certificationStatus === CERTIFICATION_STATUS.CERTIFIED ||
                solutionData.certificationStatus === CERTIFICATION_STATUS.IN_CERTIFICATION) &&
                <ConfirmationDialog
                    isOpen={isOpen}
                    onCancel={onDialogCancel}
                    onAccept={handleSubmit}
                    title={DIALOG_CONST[solutionData.certificationStatus].TITLE || ''}
                    message={DIALOG_CONST[solutionData.certificationStatus].MSG(solutionData.displayName) || ''}
                    certificationStatus={solutionData.certificationStatus}
                />}
            {showToast && (
                <Toast
                    content={toastContent}
                    id={"notification"}
                    dataTestId={"notification"}
                    visibilityDuration={0}
                    actions={[
                        {
                            onClick: () => {
                                setShowToast(false);
                            },
                            text: "Dismiss"
                        }
                    ]}
                    style={{ marginTop: "50px" }}
                    onVisibilityDurationEnd={() => {
                        setShowToast(false);
                    }}
                    position={TOAST_POSITIONS.FIXED}
                    variant={toastVariant}
                />
            )}
        </>
    )
}

const ConfirmationDialog = ({ isOpen, title, message, onCancel, onAccept, certificationStatus }) => {

    const [notes, setNotes] = useState("")

    const showNotes = certificationStatus === CERTIFICATION_STATUS.CERTIFIED
    const onInpuChange = (e) => {
        setNotes(e.target.value)
    }

    return (
        <Dialog
            buttonsProps={[
                {
                    id: "dialog-action-1",
                    onClick: onCancel,
                    text: "Cancel",
                    variant: BUTTON_VARIANTS.TEXT,
                    dataTestId: 'cancel-btn'
                },
                {
                    id: "dialog-action-2",
                    onClick: ()=>onAccept({notes}),
                    text: "Continue",
                    variant: BUTTON_VARIANTS.PRIMARY,
                    dataTestId: 'continue-btn',
                    isDisabled: showNotes ? (notes ? false : true) : false
                }
            ]}
            id={`${title}-dialog`}
            dataTestId={`${title}-dialog`}
            isOpen={isOpen}
            onClose={onCancel}
            title={title}
        >
            {message}
            {
                showNotes &&
                (
                    <div className="dialog-input-container">
                        <label>
                            {INPUT_LABEL}
                        </label>

                        <TextArea
                            dataTestId="recertification-reason"
                            errorMessage="This field is required"
                            id="recertification-reason"
                            label={INPUT_LABEL}
                            name="recertification-reason"
                            onChange={onInpuChange}
                            size="standard"
                            value={notes}
                            isRequired={true}
                            minLength={0}
                        />
                    </div>
                )
            }
        </Dialog>
    );
};

class AppDetailsContainer extends React.Component {
    render() {
        return this.props.auth.isAuthenticated ? (
            <AppDetails {...this.props} />
        ) : (
            <div></div>
        );
    }
}
export default withAuth(AppDetailsContainer);


