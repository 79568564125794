import { API_INTEGRATION_STATUS } from "../Utils/constants";
import types from "./types";

const INITIAL_STATE = {
  searchSuggestions: "",
  searchSuggestionsLoading: false,
  noResultsFound: false,
  restApiIntegrations: [],
  restApiIntegrationsLoading: false,
  asyncApiIntegrations: [],
  asyncApiIntegrationLoading: false,
  getRestIntegartionError: false,
  getAsyncIntegrationError: false,
  awaitingRestResp: false,
  updateRestResp: "",
  updateRestError: false,
  awaitingAsyncResp: false,
  updateAsyncResp: "",
  updateAsyncError: false,
  appISVDetailsLoading: false,
  appISVDetails: [],
};

const apiIntegrationManagement = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_APPS_REQUEST:
    case types.GET_APP_BY_ID_REQUEST:
      return {
        ...state,
        searchSuggestionsLoading: true,
        error: "",
      };
    case types.GET_APPS_RECEIVE:
    case types.GET_APP_BY_ID_RECEIVE: {
      let resp;
      if (action.type === types.GET_APPS_RECEIVE) {
        resp = action.response && action.response.solutions;
      } else resp = [action.response];
      let searchResult = [];
      searchResult = resp.map((sol) => ({
        id: sol.id,
        name: sol.name,
        displayName: sol.displayName,
        developerEmail: sol.developerEmail,
        createdBy: sol.createdBy,
        lastUpdatedBy: sol.lastUpdatedBy,
      }));
      return {
        ...state,
        searchSuggestionsLoading: false,
        searchSuggestions: searchResult,
        noResultsFound: searchResult.length ? false : true,
      };
    }
    case types.GET_APPS_ERROR:
    case types.GET_APP_BY_ID_ERROR:
      console.error("Error: No Solution(s) Found");
      return {
        ...state,
        searchSuggestions: "",
        searchSuggestionsLoading: false,
        noResultsFound: true,
      };
    case types.GET_ASYNC_API_INTEGRATIONS_REQUEST:
      return {
        ...state,
        asyncApiIntegrationLoading: true,
        getAsyncIntegrationError: false,
      };
    case types.GET_ASYNC_API_INTEGRATIONS_RECEIVE: {
      const resp = (action.response && action.response.items) || [];
      const asyncActiveIntegrations = resp.filter((integration) => {
        if (
          integration.asyncApi &&
          integration.asyncApi.status &&
          integration.asyncApi.status === API_INTEGRATION_STATUS.ACTIVE
        ) {
          return true;
        }
      });
      return {
        ...state,
        asyncApiIntegrations: asyncActiveIntegrations,
        asyncApiIntegrationLoading: false,
        getAsyncIntegrationError: false,
      };
    }
    case types.GET_ASYNC_API_INTEGRATIONS_ERROR:
      return {
        ...state,
        asyncApiIntegrationLoading: false,
        getAsyncIntegrationError: true,
      };
    case types.GET_REST_API_INTEGRATIONS_REQUEST:
      return {
        ...state,
        restApiIntegrationsLoading: true,
        getRestIntegartionError: false,
      };
    case types.GET_REST_API_INTEGRATIONS_RECEIVE: {
      const resp = (action.response && action.response.items) || [];
      const activeIntegrations = resp.filter((i) => i.status === "active");
      return {
        ...state,
        restApiIntegrations: activeIntegrations,
        restApiIntegrationsLoading: false,
        getRestIntegartionError: false,
      };
    }
    case types.GET_REST_API_INTEGRATIONS_ERROR:
      return {
        ...state,
        restApiIntegrationsLoading: false,
        getRestIntegartionError: true,
      };
    case types.RESET_ALL:
      return {
        ...INITIAL_STATE,
      };
    case types.UPDATE_REST_API_INTEGRATIONS_REQUEST:
      return {
        ...state,
        awaitingRestResp: true,
        updateRestError: false,
        updateRestResp: "",
      };
    case types.UPDATE_REST_API_INTEGRATIONS_RECEIVE:
      return {
        ...state,
        awaitingRestResp: false,
        updateRestResp: true,
        updateRestError: false,
      };
    case types.UPDATE_REST_API_INTEGRATIONS_ERROR:
      console.error("Error in updating Rest API Integrations");
      return {
        ...state,
        awaitingRestResp: false,
        updateRestResp: "",
        updateRestError: true,
      };
    case types.UPDATE_ASYNC_API_INTEGRATIONS_REQUEST:
      return {
        ...state,
        awaitingAsyncResp: true,
        updateAsyncResp: "",
        updateAsyncError: false,
      };
    case types.UPDATE_ASYNC_API_INTEGRATIONS_RECEIVE:
      return {
        ...state,
        awaitingAsyncResp: false,
        updateAsyncError: false,
        updateAsyncResp: true,
      };
    case types.UPDATE_ASYNC_API_INTEGRATIONS_ERROR:
      console.error("Error in updating Async API Integrations");
      return {
        ...state,
        awaitingAsyncResp: false,
        updateAsyncError: true,
        updateAsyncResp: "",
      };
    case types.RESET_PATCH_API_DATA:
      return {
        ...state,
        awaitingRestResp: false,
        updateRestResp: "",
        updateRestError: false,
        awaitingAsyncResp: false,
        updateAsyncResp: "",
        updateAsyncError: false,
      };
    case types.RESET_SEARCH_DATA:
      return {
        ...state,
        searchSuggestions: "",
        searchSuggestionsLoading: false,
        noResultsFound: false,
      };
    case types.GET_APP_ISV_DETAILS_REQUEST:
      return {
        ...state,
        appISVDetailsLoading: true,
      };
    case types.GET_APP_ISV_DETAILS_RECEIVE:
      return {
        ...state,
        appISVDetails: [...state.appISVDetails, action.response.profile],
        appISVDetailsLoading: false,
      };
    case types.GET_APP_ISV_DETAILS_ERROR:
      console.error("Error in getting user Details");
      return {
        ...state,
        appISVDetailsLoading: false,
      };
    case types.SEND_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.SEND_EMAIL_RECEIVE:
      return {
        ...state,
        isLoading: false,
      };
    case types.SEND_EMAIL_ERROR:
      console.error("Error in sending email to ISV");
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default apiIntegrationManagement;
